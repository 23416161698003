@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportCustomXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (--viewportCustom2XLarge) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.noSearchListingCards {
  padding: 0 0 72px 0;

  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.listingCard {
  width: 100%;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

.storesContainer {
  display: grid;
  gap: 1em;
  margin-top: 5em;

  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.toggleMap {

  display: none;
  
  @media (--viewportLarge) {
    display: block;
    
    margin: 2em auto 0;
    width: fit-content;
    padding: 0.5em 1em;
    font-size: 12px;
    min-height: unset;
    height: fit-content;
    border-radius: 5em;
    position: sticky;
    bottom: 2.2em;
  }
}
