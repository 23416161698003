@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.createListingLink,
.resetAllFiltersButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  display: inline;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
  }
}

.pContainer {
  margin: 2em 0;
}

.providerSignupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 1em 0;

  /* color: var(--colorGrey700); */
  /* color: var(--colorBlack); */

  font-weight: bold !important;

  &:hover {
    /* color: var(--colorBlack); */
    /* color: var(--huntingOrange); */
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }

  & > span {
    border: 2px solid var(--huntingOrange);
    color: var(--huntingOrange);
    border-radius: 5px;
    padding: 1em;
    font-weight: var(--fontWeightMedium);

    &:hover {
      border: 2px solid var(--huntingOrangeDark);
      color: var(--huntingOrangeDark);
    }
  }
}

.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--marketplaceColor);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.customCreateListingLink {
  border: 1px solid black;
  border-radius: 6px;
  height: 3em;
  margin-top: 1em;
  display: flex;
  align-items: center;
  width: 10em;
  justify-content: center;

  &:hover {
    border-bottom: 1px solid black !important;
    box-shadow: 2px 2px 5px gray
  }

  & span {
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}
